import {
  object,
  string,
  boolean,
  array,
  number,
  type Input,
  union,
  optional,
} from "valibot";
import {
  searchRestaurant as fetchSearchRestaurant,
} from "~/api/restaurant/searchRestaurant";
import adsMapper from "~/helpers/adsMapper";
import {
  isFeaturedRestaurant,
  restaurant as createRestaurant,
  featuredResturant as createFeaturedRestaurant,
} from "~/models/restaurant";
import { useReport } from "~/composables/useReport";

const schema = object({
  keyword: optional(string()),
  location: optional(
    object({
      lat: optional(number()),
      lng: optional(number()),
      long: optional(number()),
      distance: optional(union([string(), number()])),
      isNearMe: optional(string()),
    })
  ),
  tagsId: optional(union([number(), string()])),
  branchId: optional(union([number(), string()])),
  pageNumber: number(),
  pageSize: number(),
  cityId: optional(union([number(), string()])),
  startPrice: optional(number()),
  endPrice: optional(number()),
  priceType: optional(string()),
  serviceType: optional(string()),
  groupTagsId: optional(string()),
  sortBy: optional(string()),
  date: optional(string()),
  time: optional(string()),
  packageType: optional(string()),
  section: optional(string()),
  tagIds: optional(string()),
  locationIds: optional(
    union([
      array(
        object({
          id: union([number(), string()]),
          name: string(),
          type: optional(string()),
        })
      ),
      string(),
    ])
  ),
  cuisineIds: optional(
    union([
      array(object({ id: union([number(), string()]), name: string() })),
      string(),
    ])
  ),
  facilityIds: optional(
    union([
      array(object({ id: union([number(), string()]), name: string() })),
      string(),
    ])
  ),
  diningStyleIds: optional(
    union([
      array(object({ id: union([number(), string()]), name: string(), type: optional(string()) })),
      string(),
    ])
  ),
  multiplePricing: optional(boolean()),
  offerTicket: optional(boolean()),
  acceptVoucher: optional(boolean()),
  priceFilter: optional(
    object({
      priceType: string(),
      min: number(),
      max: number(),
      symbolCurrency: optional(string()),
    })
  ),
  people: optional(
    object({
      kids: optional(number()),
      adults: optional(number()),
    })
  ),
});

export type SearchParams = Input<typeof schema>;

export async function searchRestaurant(param: SearchParams) {
  const { success, data, message } = await fetchSearchRestaurant(param);

  if (!success) {
    useReport({
      level: "error",
      message,
    });
    return {
      success,
      data,
      message,
    };
  }

  if (data) {
      const restaurantResults = data.data.map((restaurant) =>
      isFeaturedRestaurant(restaurant)
        ? createFeaturedRestaurant(restaurant)
        : createRestaurant(restaurant)
    );

    const totalRestaurants = data.metaData.totalRestaurants;

    if (Array.isArray(data.ads)) {
      data.ads.forEach((ad) => {
        adsMapper(ad, data.data, param.pageSize || 10, param.pageNumber || 1);
      });
    }

    return {
      success,
      message,
      data: {
        links: data.links,
        data: restaurantResults,
        totalRestaurants,
      },
    };
  } 

  return {
    success: false,
    data: {
      links: null,
      data: null,
      totalRestaurants: 0,
    },
    message,
  };
}
